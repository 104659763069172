  <template>
  <div class="">
      <div
        class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing "
      >
      <div class="row">
        <span class="text-center">
        <h4 class="fw-semibold mb-8">
          Instalace:
          <span class="fw-bold m-5">{{route.params.appuuid}}</span>
          <span v-if="detailData.appuuidAlias != null && detailData.appuuidAlias.length > 0">
            Název: 
              <span class="fw-bold ">{{detailData.appuuidAlias}}</span>
          </span>
        </h4>
        </span>
        <div>
          <div class="card-body d-flex justify-content-center">
            <ul
              class="nav nav-tabs bg-white border-0 rounded-0 max-selection-width"
              role="tablist"
            >
              <li v-for="(item, index) in detailData.products" :key="item.productName" class="nav-item" role="tablist">
                <button 
                  :class="['nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100', { active: (index==selectedProductIndex)?true:false }]"
                  role="button"
                  :aria-selected="(index==selectedProductIndex)?true:false"
                  @click="selectProduct(index)"
                >{{ item.productName }}
                </button>
              </li>
            </ul>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-6 max-selection-width">
              <label class="d-block text-black fw-semibold mb-10 mt-2">Zobrazení</label>
              <select
                v-model="displayOption"
                class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10"
              >
                <option value="event">Události</option>
                <option value="table" :disabled="detailData.companyTimeLineGraph != undefined && detailData.companyTimeLineGraph.length == 0">Firmy</option>
              </select>
            </div>
            <div class="col-12 col-md-6 max-selection-width">
              <label class="d-block text-black fw-semibold mb-10 mt-2 mr-2">Výběr firmy </label>
              <select
                v-model="selectedCompany"
                class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10"
                :disabled="displayOption!='table'"
              >
                <template v-if="detailData.products[selectedProductIndex] != undefined">
                  <option v-for="option in detailData.products[selectedProductIndex].companyNames"
                    :key="option" 
                    :value="option.value">
                      {{option.text}}
                  </option>
                </template>
              </select>
            </div>
          </div>
        </div>
      <div class="col-12 col-sm-6">
        <div class="card-body">
          <span v-if="detailData.products.length>0" class="row justify-content-left p-3">
            <div
              class="card mb-25 ml-1 border-0 rounded-0 bg-white letter-spacing col-auto"
            >
              <div class="card-body shift-card-body">
                <div class=" align-items-center">
                  <div class="">
                    <div class="product-details-content">
                      <ul class="info ps-0 list-unstyled d-inline-block">
                        <li class="text-para fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Aktuální verze: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].currentProductVersion}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Datum instalace aktuální verze: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].currentProductDate}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Licence: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].currentProductLicense}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Počet spravovaných firem: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].latestCompanyCount}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Počet modulů: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].moduleCount}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Počet událostí: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].eventCount}}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card mb-25 ml-1 border-0 rounded-0 bg-white letter-spacing col-auto"
            >
              <div class="card-body shift-card-body">
                <div class=" align-items-center">
                  <div class="">
                    <div class="product-details-content">
                      <ul class="info ps-0 list-unstyled d-inline-block">
                        <li class="text-paragraph fs-md-15 fs-xxxl-16"> 
                          <span class="text-black fw-semibold d-inline-block">Předchozí verze: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].previousProductVersion}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Datum instalace předchozí verze: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].previousProductDate}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Lincence předchozí verze: </span>
                          <span class="overView-value">{{detailData.products[selectedProductIndex].previousProductLicense}}</span>
                        </li>
                        <template v-if="detailData.products[selectedProductIndex].companyEvents.find((item)=> item.firuuid == selectedCompany) != undefined && displayOption == 'table'">
                          <li class="text-paragraph fs-md-15 fs-xxxl-16">
                            <span class="text-black fw-semibold d-inline-block">Typ účetnictví: </span>
                            <span class="overView-value">{{getUctType(detailData.products[selectedProductIndex].companyEvents.find((item)=> item.firuuid == selectedCompany))}}</span>
                          </li>
                          <li class="text-paragraph fs-md-15 fs-xxxl-16">
                            <span class="text-black fw-semibold d-inline-block">Typ organizace: </span>
                            <span class="overView-value">{{getOrgType(detailData.products[selectedProductIndex].companyEvents.find((item)=> item.firuuid == selectedCompany))}}</span>
                          </li>
                        </template> 
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <!--<span class="p-15">
            <button class="default-btn position-relative transition border-0 fw-medium text-white mb-3 pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0">
                Zobrazit statistiky
            </button>
          </span>-->
          <span class="p-15">
            <button class="default-btn position-relative transition border-0 fw-medium text-white mb-3 pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
              @click="redirectToInfo(route.params.appuuid)"
            >
              {{infoButtonText}}
            </button>
          </span>
        </div>
        </div>
        <div class="col-12 col-sm-6">
          <EventCountGraph :detailData="detailData.products[selectedProductIndex]" :dataType="displayOption" :selectedCompany="selectedCompany"/>
        </div>
        <div v-if="detailData.eventFrequencyStackedColumnData != undefined && displayOption == 'event'" class="col-12">
          <EventFrequencyStackedColumn :detailData="detailData.eventFrequencyStackedColumnData[detailData.eventFrequencyStackedColumnData.findIndex(item => item.productName == detailData.products[selectedProductIndex].productName)].data "/>
        </div>
        <div v-if="detailData.companyTimeLineGraph != undefined && selectedCompany != undefined && displayOption == 'table'">
          <CompanyTimelineGraphVue :detailData="detailData.companyTimeLineGraph.find(item => item.firuuid == selectedCompany).data"/>
        </div>
      </div>
    </div>
  <div class="card border-0 rounded-0 bg-white">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div
        class="d-sm-flex align-items-center justify-content-between"
      >
        <div class="col-12">
          <div
            class="card border-0 rounded-0 bg-white checkout-box letter-spacing "
          >
            <div class="card-body ">
              <ul
                class="nav nav-tabs bg-white border-0 rounded-0"
                role="tablist"
              >
                <li class="nav-item" role="tablist">
                  <button 
                    :class="['nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100', { active: (selectedHistoryType == 'event')?true:false }]"
                    role="button"
                    :aria-selected="(selectedHistoryType == 'version')?true:false"
                    @click="selectHistory('event')"
                  >Historie událostí
                  </button>
                </li>
                <li class="nav-item" role="tablist">
                  <button 
                    :class="['nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100', { active: (selectedHistoryType == 'version')?true:false }]"
                    role="button"
                    :aria-selected="(selectedHistoryType == 'version')?true:false"
                    @click="selectHistory('version')"
                  >Historie verzí
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--<h5 class="card-title fw-bold mb-0">Historie událostí</h5>-->
      </div>
    </div>
  </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { toRefs, ref, computed, watch } from 'vue'
import { getTypeOrg, getTypeUct} from "../../../utils/shared";
//Component imports
import EventCountGraph from "./EventCountGraph.vue";
import EventFrequencyStackedColumn from "./EventFrequencyStackedColumn.vue";
import CompanyTimelineGraphVue from "./CompanyTimelineGraph.vue";

export default defineComponent({
  props: ['detailData'],
  name: "installationTable",
  emits: ['installationDetailChange'],
  components: {
    EventCountGraph,
    EventFrequencyStackedColumn,
    CompanyTimelineGraphVue
  },
  setup(props, context){
    const route = useRoute();
    const router = useRouter();
    let { detailData } = toRefs(props);
    let selectedProductIndex = ref(0);
    let selectedHistoryType = ref('event');
    let selectedCompany = ref('all');
    let displayOption = ref('event');
    const infoButtonText = computed(() =>{
      return "Přidat informace";
    })
    const selectProduct = (index) =>{
      for(let key in detailData.value.products){
        if(key == index){
          selectedProductIndex.value = index;
          context.emit('installationDetailChange');
        }
      }
    }
    const selectHistory = (historyType: string) => {
      selectedHistoryType.value = historyType;
      context.emit('installationDetailChange');
    }
    const getSelectedProductIndex = () =>{
      return selectedProductIndex.value;
    }
    const getInstallationType =() =>{
      return selectedHistoryType.value;
    } 

    const getUctType = (data: any) =>{
      const uctTypes = getTypeUct();
      const selectedType :Array<object>|any = uctTypes.find((item) => item.value == JSON.parse(data.firinfo).typUct)
      return selectedType.text
    }

    const getOrgType = (data: any) =>{
      const orgTypes = getTypeOrg();
      const selectedType :Array<object>|any = orgTypes.find((item) => item.value == JSON.parse(data.firinfo).typUct)
      return selectedType.text
    }

    watch(displayOption, () =>{
      //console.log("TEST");
    })

    watch(selectedCompany, () =>{
      //console.log("ZMENA FIRMY");
    })

    const redirectToInfo = (appuuid: string) =>{
      router.push({ name: 'Installation-info', params: { appuuid: appuuid }})
    }

    return{
      route,
      selectedCompany,
      selectedProductIndex,
      selectedHistoryType,
      selectHistory,
      selectProduct,
      redirectToInfo,
      getSelectedProductIndex,
      getInstallationType,
      getUctType,
      getOrgType,
      infoButtonText,
      displayOption
    }
  }
  

});
</script>

<style scoped>
.shift{
  margin-left: 50px;
}
.overView-value{
  padding: 5px;
}
.max-selection-width{
 max-width: 10px;
 min-width: 400px;
}

</style>