<template>
	<span class="row">
		<span class="row">
			<div class="form-group col-12 col-md-6">
				<label class="d-block text-black fw-semibold mb-10">Výběr od</label>
				<div class="input-group">
					<span class="input-group-text rounded-0 fs-14 text-muted">
						<i class="flaticon-calendar lh-1 position-relative top-1"></i>
					</span>
					<input
						v-model="borderDates.startDate"
						@change="setCustomDateSelection"
						type="date"
						class="form-control shadow-none rounded-0 text-black me-1"
					/>
				</div>
			</div>
			<div class="form-group col-12 col-md-6">
				<label class="d-block text-black fw-semibold mb-10">Výběr do</label>
				<div class="input-group">
					<span class="input-group-text rounded-0 fs-14 text-muted">
						<i class="flaticon-calendar lh-1 position-relative top-1"></i>
					</span>
					<input
						v-model="borderDates.endDate"
						@change="setCustomDateSelection"
						type="date"
						class="form-control shadow-none rounded-0 text-black me-1"
					/>
				</div>
	
			</div>  
		</span>
		<div class="col-6">
			<label class="d-block text-black fw-semibold mb-10">Časové období:</label>
			<select
				v-model="timeSelected"
				@change="changeBorderDates(timeSelected)"
				class="form-select shadow-none fw-semibold rounded-0 col-6"
			>
				<option value="day" >Dnes</option>
				<option value="week" >Týden</option>
				<option value="month">Měsíc</option>
				<option value="30days" >30 dní</option>
				<option value="60days" >60 dní</option>
				<option value="90days" >90 dní</option>
				<option value="120days" >120 dní</option>
				<option value="custom">Vlastní</option>
			</select>
		</div>
	</span>
</template>

<script lang="ts">
	import {defineComponent, toRefs, ref, onMounted, watch} from 'vue';

	//Dayjs imports
	import dayjs from 'dayjs';
	import utc from 'dayjs/plugin/utc';
	import timezone from 'dayjs/plugin/timezone';

	export default defineComponent({
		emits: ['selectedDateChanged'],
		setup(props, context){			
			//Time related varaibles
			let borderDates = ref<{ startDate: any, endDate: any }>({ startDate: new Date().toISOString().split("T")[0], endDate: new Date().toISOString().split("T")[0] });
			let timeSelected = ref("120days");

			//Dayjs settings
			dayjs.extend(utc);
			dayjs.extend(timezone);
			const dayjsInstance = dayjs().tz('Europe/Prague');

			onMounted(() =>{
				changeBorderDates(timeSelected.value);
			})

			const changeBorderDates = (dateRangeName :string) => {
				let startDate :Date;
				let endDate = dayjsInstance.startOf("day").toDate();
				if(dateRangeName == "30days"){
					startDate = dayjsInstance.subtract(30, 'days').startOf('day').toDate();
				}else if(dateRangeName == "60days"){
					startDate = dayjsInstance.subtract(60, 'days').startOf('day').toDate();
				}else if(dateRangeName == "90days"){
					startDate = dayjsInstance.subtract(90, 'days').startOf('day').toDate();
				}else if(dateRangeName == "120days"){
					startDate = dayjsInstance.subtract(120, 'days').startOf('day').toDate();
				}else if(dateRangeName == "day"){
					startDate = dayjsInstance.startOf('day').toDate();
					endDate = dayjsInstance.endOf('day').toDate();
				}else if(dateRangeName == "week"){
					startDate = dayjsInstance.startOf('week').toDate();
					endDate = dayjsInstance.endOf('week').toDate();
				}else if(dateRangeName == "month"){
					startDate = dayjsInstance.startOf('month').toDate();
					endDate = dayjsInstance.endOf('month').toDate();
				}else{
					startDate = dayjsInstance.startOf('day').toDate();
				}
				borderDates.value = {startDate: startDate.toISOString().split("T")[0], endDate: endDate.toISOString().split("T")[0]};
				context.emit("selectedDateChanged", borderDates.value); 
				return "done";
			}
			const setCustomDateSelection = () =>{
				timeSelected.value = "custom";
				context.emit("selectedDateChanged", borderDates.value, timeSelected.value); 
			}
			return{
				changeBorderDates: changeBorderDates,
				setCustomDateSelection: setCustomDateSelection,
				borderDates: borderDates,
				timeSelected: timeSelected
			}
		}
	})
</script>