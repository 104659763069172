
  function generateRandomString(size :number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < size) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  function getTypeOrg(){
    const types = [
      {text: "Podnikatelský subjekt", value: 1},
      {text: "Příspěvková organizace", value: 2},
      {text: "Nezisková organizace", value: 3},
    ]
    return types;
  }

  function getTypeUct(){
    const types = [
      {text: "Podvojné účetnictví", value: 1},
      {text: "Daňová evidence", value: 2},
    ]
    return types;
  }

  function getTypeLicense(){
    const types = [
      {text: "DEMO", value: "DEMO"},
      {text: "FULL", value: "FULL"},
      {text: "TRIAL", value: "TRIAL"},
    ]
    return types;
  }

  
  export { generateRandomString, getTypeOrg, getTypeUct , getTypeLicense}