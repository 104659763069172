<template>
  <div class="row">
    <Overview :overViewData="overViewData"/>
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { ref } from 'vue';

import Overview from "../../components/Implementation/DocumentInfo/DocumentInfoOverview.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    Overview
  },
  setup(){
    let overViewData = ref({
      "productList":[{"text":"DUNA", value: "DUNA"},{"text": "MEDIC", "value": "MEDIC"}, {"text": "MZDY", "value": "DUNA MZDY"}, {"text": "CASH", "value": "CASH"}],
      "tableList:":[]
    })

    let tableOptions = ref({});

    return{
      tableOptions,
      overViewData
    }
  }

});

</script>