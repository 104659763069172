<template>
  <div>
    <div id="wrapper">
      <div v-if ="preparedData.series != undefined && graphData.length != 0" id="chart">
        <apexchart :options="preparedData.chartOptions" :series="preparedData.series" ></apexchart>
			<span>
				<label class="d-block text-black fw-semibold mb-10">Zobrazit záznamů:</label>
				<select
					v-model="shownRecordCount"
					@change="changeShownRecordCount"
					class="form-select shadow-none fw-semibold rounded-0"
				>
				<option value="10">10</option>
				<option value="20">20</option>
				<option value="30">30</option>
				<option value="all">Vše</option>
				</select>
			</span>
      </div>		
			<div v-else class="d-flex justify-content-center">
				Žádné záznamy
			</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import { toRefs, ref, watch, onMounted } from 'vue'

	//Dayjs imports
	import dayjs from 'dayjs';

  export default defineComponent({
    props: ['graphData','borderDates'],
    name: "EvidencePieGraph",
    setup(props){
      let { graphData } = toRefs(props);
      let preparedData = ref({}); 
			let shownRecordCount = ref("10");
      //let data = ref<Array<{ version: string; usage: number }>>([]);

      watch(graphData, () => {
        preparedData.value = prepareData();  
      });

			onMounted(()=>{
				preparedData.value = prepareData();
			})

			const changeShownRecordCount =()=>{
				preparedData.value = prepareData();  
			}
			const prepareData = () => {
				graphData.value = graphData.value.sort(function(a, b) {
					return b.usageCount - a.usageCount;
				});
				let shownRecords = (shownRecordCount.value == "all")?graphData.value.length:Number(shownRecordCount.value);
				let preparedData = {
					series: graphData.value.map(item => item.usageCount).slice(0,shownRecords),
					chartOptions: {
						chart: {
							type: 'pie',
						},
						labels: graphData.value.map(item => item.evidence).slice(0,shownRecords),				
						responsive: [
							{
								breakpoint: 950,
								options: {
									legend: {
										position: 'bottom'
									}	
								}
							}
						],
						title: {
							text: "Četnost událostí od: "+dayjs(props.borderDates.startDate).format('DD.MM.YYYY')+" do: "+dayjs(props.borderDates.endDate).format('DD.MM.YYYY')
						},
					},
				}
				return preparedData;
			}   
		return{
			prepareData,
			preparedData,
			shownRecordCount,
			changeShownRecordCount
		}
  }
  });
</script>
<style>
.apexcharts-tooltip span {
	color: black !important;
}
</style>>